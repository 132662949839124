import React, { useState, useEffect } from 'react';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import DisclaimerCmp from '../components/DisclaimerCmp';

import { Result } from '../types/Result';
import ResultCmp from '../components/ResultCmp';
import { resultsTitle, resultsData } from '../data/resultsData';

const ResultsPage = () => {
  const [title, setTitle] = useState<string>('');
  const [results, setResults] = useState<Result[]>([]);

  useEffect(() => {
    setTitle(resultsTitle);
    setResults(resultsData);
  }, []);

  return (
    <Layout>
      <Seo title='Past Results' />

      <div className='max-w-6xl p-2 mt-4 sm:m-auto sm:p-12'>
        <div className='pt-2 pb-6 mx-auto mt-2 border-b-4 sm:block'>
          <h1 className='py-4 text-center font-slim text-primary'>{title}</h1>

          <div className='grid justify-center grid-cols-1 gap-4 px-6 my-4 md:grid-cols-3 sm:w-auto'>
            {results.map((result, index) => (
              <ResultCmp
                key={index}
                id={result.id}
                type={result.type}
                subtype={result.subtype}
                amount={result.amount}
                description={result.description}
              />
            ))}
          </div>
        </div>

        <DisclaimerCmp />
      </div>
    </Layout>
  );
};

export default ResultsPage;

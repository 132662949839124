import React from 'react';

const DisclaimerCmp = () => (
  <div className='h-16 my-5 mt-10 text-center dark-blue'>
    <strong>
      <em>
        <span className='underline'>Legal Disclaimer</span>
        <br />
        Each case is unique and has specific facts and circumstances. These past
        results are not a guarantee of future outcomes in any case.
      </em>
    </strong>
  </div>
);

export default DisclaimerCmp;

import React from 'react';

import { Result } from '../types/Result';

function ResultCmp({ type, subtype, amount, description }: Result) {
  return (
    <>
      <div className='flex flex-col content-between result sm:max-w-sm base-text-color'>
        <div className='px-6 py-4'>
          <div className='mb-2 text-xl font-bold'>{subtype}</div>
          <div className='mb-2 text-sm italic dark-blue'>
            {type}, {amount}
          </div>
          <p className='mb-4 text-base'>{description}</p>
        </div>
      </div>
    </>
  );
}

export default ResultCmp;
